import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import SegmentedControl from '@amzn/awsui-components-react/polaris/segmented-control';

import Catalog from '../Catalog/Catalog';
import LoadingSkeleton from '../LoadingSkeleton';
import {
  createSegmentedOptions,
  getFirstKey,
  getCatalogItems,
  updateSearchQueryString,
} from './utils';
import { useLocaleContext } from '../../contexts';
import { getLocaleOrAvailable } from '../../utils/localeUtils';
import metrics from '../../utils/metrics';
import useGetUnauthenticatedCatalog from '../../hooks/useGetUnauthenticatedCatalog';

const UnauthenticatedCatalog = () => {
  const [refinerState, setRefinerState] = useState({
    matchesFilterQuery: () => true,
    searchText: '',
    searchResult: null,
  });
  const metricsPublisher = useRef(metrics.createPublisher('LabContext'));
  const history = useHistory();
  const [locale] = useLocaleContext();
  const { data, isLoading, isError } = useGetUnauthenticatedCatalog({
    metricsPublisher: metricsPublisher.current,
  });
  const [catalog, setCatalog] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const options = createSegmentedOptions(data, locale);

  useEffect(() => {
    if (!data) return;

    const currentSelectionId = selectedId || getFirstKey(data);
    const experience = getLocaleOrAvailable(locale, data[currentSelectionId]);
    const catalogItems = getCatalogItems(data, currentSelectionId);

    setSelectedId(currentSelectionId);
    setSelectedExperience(experience);
    setCatalog(catalogItems);
    updateSearchQueryString(history);
    setRefinerState(prevState => ({
      ...prevState,
      searchText: '',
      searchResult: null,
    }));
  }, [data, locale, selectedId, history]);

  return (
    <SpaceBetween direction="vertical" size="l">
      {options && selectedId ? (
        <SegmentedControl
          selectedId={selectedId}
          onChange={({ detail }) => setSelectedId(detail.selectedId)}
          label="Catalog options"
          options={options}
        />
      ) : (
        <LoadingSkeleton width={50} height={32} />
      )}

      {selectedExperience ? (
        <Box>{selectedExperience.description}</Box>
      ) : (
        <SpaceBetween direction="vertical" size="xs">
          <LoadingSkeleton width={60} height={24} />
          <LoadingSkeleton width={100} height={24} />
        </SpaceBetween>
      )}

      <Catalog
        isLoading={isLoading}
        isError={isError}
        catalogList={catalog}
        refinerState={refinerState}
        setRefinerState={setRefinerState}
        localePref={locale}
        disableLinks={true}
      />
    </SpaceBetween>
  );
};

export default UnauthenticatedCatalog;
