import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';

import { useAuthContext } from '../contexts';
import { ButtonOverride } from '../components/PolarisOverrides';
import {
  publishButtonClickMetric,
  publishCounterMetric,
} from '../utils/metrics';
import { URL_PATHS } from '../constants/urlPaths';
import HeaderEH from '../components/Header/HeaderEH';
import HeaderWrapper from '../components/Header/HeaderWrapper';
import Main from '../components/Main';
import { AppLayoutOverride } from '../components/PolarisOverrides';
import SEO from '../components/SEO';
import UnauthenticatedCatalog from '../components/UnauthenticatedCatalog/UnauthenticatedCatalog';
import useAppLayoutLabels from '../hooks/useAppLayoutLabels';
import messages from './UnauthenticatedCatalogPage.messages';
import { getQueryParam } from '../utils/queryParamUtils';
import { MODE_QUERY_PARAM, KIOSK_MODE } from '../constants/catalog';

const UnauthenticatedCatalogPage = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const location = useLocation();
  const [{ isAuthenticated, currentUser }] = useAuthContext();
  const APP_LAYOUT_LABELS = useAppLayoutLabels();
  const appLayoutRef = useRef();

  const [isKioskMode] = useState(
    getQueryParam(history.location.search, MODE_QUERY_PARAM) === KIOSK_MODE
  );

  useEffect(() => {
    publishCounterMetric('UnauthenticatedCatalogPage', {
      additionalMetrics: {
        PathName: window.location.pathname,
      },
    });
  }, []);

  const HeaderActions = ({ isKioskMode }) => {
    if (isKioskMode) return null;
    return (
      <SpaceBetween direction="horizontal" size="xs">
        <ButtonOverride
          variant="primary"
          onClick={() => {
            publishButtonClickMetric('UnauthenticatedCatalogCTA', {
              destinationUrl: URL_PATHS.homePage,
              currentPath: location.pathname,
              clickLocation: 'UnauthenticatedCatalog',
            });
            history.push(URL_PATHS.homePage);
          }}
        >
          {formatMessage(messages.cta)}
        </ButtonOverride>
      </SpaceBetween>
    );
  };

  return (
    <>
      <SEO title={formatMessage(messages.title)} />
      <HeaderWrapper>
        <HeaderEH
          currentUser={currentUser}
          isAuthenticated={isAuthenticated}
          isStandalone={true}
          disableSignIn={true}
        />
      </HeaderWrapper>
      <AppLayoutOverride
        forwardRef={appLayoutRef}
        maxContentWidth={800}
        content={
          <Main>
            <SpaceBetween direction="vertical" size="l">
              <Header
                variant="h1"
                actions={<HeaderActions isKioskMode={isKioskMode} />}
              >
                {formatMessage(messages.title)}
              </Header>
              <TextContent>{formatMessage(messages.description)}</TextContent>
              <UnauthenticatedCatalog />
            </SpaceBetween>
          </Main>
        }
        headerSelector=".HeaderWrapper"
        footerSelector=".Footer"
        stickyNotifications={true}
        ariaLabels={APP_LAYOUT_LABELS}
        navigationHide={true}
        toolsHide={true}
      />
    </>
  );
};

export default UnauthenticatedCatalogPage;
