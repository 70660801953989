import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { prop } from 'ramda';
import LoadingPage from './pages/LoadingPage';
import ErrorPage from './pages/ErrorPage';
import RecommendationsPage from './pages/RecommendationsPage';
import CatalogPage from './pages/CatalogPage';
import LabPage from './pages/LabPage';
import NotFoundPage from './pages/NotFoundPage';
import RedirectPage from './pages/RedirectPage';
import UnauthenticatedCatalogPage from './pages/UnauthenticatedCatalogPage';
import {
  useLocaleContext,
  useAuthContext,
  LabContextProvider,
  useEventContext,
  TourContextProvider,
  useConfigContext,
} from './contexts';
import useScrollToTop from './hooks/useScrollToTop';
import useEventAnnouncements from './hooks/useEventAnnouncements';
import { URL_PATHS } from './constants/urlPaths';
import useRecordPageView from './hooks/useRecordPageView';
import metrics from './utils/metrics';
import { handleSignIn } from './utils/authUtils';
import LandingPage from './pages/LandingPage';
import logger from './utils/logger';

const App = () => {
  useScrollToTop();
  useEventAnnouncements();
  useRecordPageView(metrics.recordRumPageView);
  const [{ isAuthenticated, currentUser }] = useAuthContext();
  const { event, isLoading, isError, error } = useEventContext();
  const [locale] = useLocaleContext();
  const config = useConfigContext();

  const enableLandingPage = prop('enableLandingPage', config) === 'true';
  const enableUnauthenticatedCatalogPage =
    prop('enableUnauthenticatedCatalogPage', config) === 'true';
  const isUnauthenticatedCatalogPage =
    enableUnauthenticatedCatalogPage &&
    location.pathname === URL_PATHS.unauthenticatedCatalogPage;

  useEffect(() => {
    if (isAuthenticated || enableLandingPage || isUnauthenticatedCatalogPage)
      return;
    handleSignIn();
  }, [isAuthenticated, enableLandingPage, isUnauthenticatedCatalogPage]);

  if (!isAuthenticated) {
    // Return unauthenticated routes for configured events.
    if (enableLandingPage || enableUnauthenticatedCatalogPage) {
      return (
        <Switch>
          {enableUnauthenticatedCatalogPage && (
            <Route
              exact
              path={URL_PATHS.unauthenticatedCatalogPage}
              render={() => <UnauthenticatedCatalogPage />}
            />
          )}
          {enableLandingPage && (
            <Route
              render={() => <LandingPage hostname={location.hostname} />}
            />
          )}
        </Switch>
      );
    }
    // Return a blank UI by default while authentication flows are happening in the background.
    return null;
  }

  if (isLoading) return <LoadingPage />;

  if (isError || !event.arn) {
    logger.error(`Error loading the event`, error, event);
    return (
      <TourContextProvider>
        <ErrorPage
          errors={prop('errors', error)}
          isAuthenticated={isAuthenticated}
          currentUser={currentUser}
        />
      </TourContextProvider>
    );
  }

  return (
    <LabContextProvider localePref={locale} isAuthenticated={isAuthenticated}>
      <TourContextProvider>
        <Switch>
          {event.isStandaloneLabEvent() ? null : (
            <Route
              exact
              path={URL_PATHS.homePage}
              render={() => <RecommendationsPage localePref={locale} />}
            />
          )}
          {event.isStandaloneLabEvent() ? null : (
            <Route
              exact
              path={URL_PATHS.catalogPage}
              render={props => <CatalogPage localePref={locale} {...props} />}
            />
          )}
          {enableUnauthenticatedCatalogPage && (
            <Route
              exact
              path={URL_PATHS.unauthenticatedCatalogPage}
              render={() => <UnauthenticatedCatalogPage />}
            />
          )}
          <Route
            exact
            path={`${URL_PATHS.labPage}/:encodedBlueprintArn/:blueprintLocale?/:labId?`}
            render={props => (
              <LabPage
                {...props}
                rootPath={URL_PATHS.labPage}
                isStandalone={event.isStandaloneLabEvent()}
              />
            )}
          />
          <Route
            exact
            path={`${URL_PATHS.redirectPage}/:redirectId`}
            render={props => <RedirectPage {...props} />}
          />
          <Route
            exact
            path={`${URL_PATHS.standaloneLabPage}/:encodedBlueprintArn/:blueprintLocale?/:labId?`}
            render={props => (
              <LabPage
                {...props}
                isStandalone
                rootPath={URL_PATHS.standaloneLabPage}
              />
            )}
          />
          <Route render={() => <NotFoundPage />} />
        </Switch>
        <ReactQueryDevtools initialIsOpen={false} />
      </TourContextProvider>
    </LabContextProvider>
  );
};

App.propTypes = {};

export default App;
